import jQuery from "jquery";
import "slick-carousel";

jQuery(function ($) {
  /*
  ----------------------------------------------------------------------------------
  LANGUAGE SELECTOR
  */
  const languageToggles = document.querySelectorAll('.language-selector_toggle');
  const languageMenus = document.querySelectorAll('.language-selector_menu');

  languageToggles.forEach((toggle, index) => {
    toggle.addEventListener('click', function () {
      this.classList.toggle('active');
      const isExpanded = this.getAttribute('aria-expanded') === 'true';
      this.setAttribute('aria-expanded', !isExpanded);
      languageMenus[index].classList.toggle('show');
    });
  });


  /*
  ----------------------------------------------------------------------------------
  MOBILE NAV FUNCTIONALITY
  */

  let toggle = document.getElementById("mobile-nav-toggle");
  let container = document.getElementById("mobile-nav-container");
  let html = document.querySelector("html");
  let body = document.querySelector("body");
  toggle.addEventListener("click", () => {
    if (toggle.hasAttribute("data-nave-toggle")) {
      container.removeAttribute("data-nav-container");
      toggle.removeAttribute("data-nave-toggle");
      html.classList.remove("no-scroll");
      body.classList.remove("no-scroll");
    } else {
      container.setAttribute("data-nav-container", "");
      toggle.setAttribute("data-nave-toggle", "");
      html.classList.add("no-scroll");
      body.classList.add("no-scroll");
    }
  });

  $(".mobile--about-us").click(function () {
    // $('.mobile--investors').slideToggle();
    // $('.mobile--about-us .sub-menu').slideToggle();
    // $(' .mobile--about-us').toggleClass('mobile--disabled')
    if ($(".mobile--about-us .sub-menu").is(":visible")) {
      $(".mobile--about-us .sub-menu").slideUp();
      $(".mobile--about-us ").removeClass("mobile--disabled");
      $(".mobile--investors ").slideUp();
      $(".mobile--investors .sub-menu ").slideUp();
      $(".mobile--investors ").removeClass("mobile--disabled");
    } else {
      $(".mobile--about-us .sub-menu").slideDown();
      $(".mobile--about-us ").addClass("mobile--disabled");
      $(".mobile--investors ").slideDown();
    }
  });

  $(".mobile--investors").click(function () {
    $(".mobile--investors .sub-menu").slideToggle();
    $(".mobile--investors").toggleClass("mobile--disabled");
  });

  $(".mobile--resources").click(function () {
    $(".mobile--resources .sub-menu").slideToggle();
    $(".mobile--resources").toggleClass("mobile--disabled");
  });

  $(".mobile--getting-barostim").click(function () {
    $(".mobile--getting-barostim .sub-menu").slideToggle();
    $(".mobile--getting-barostim").toggleClass("mobile--disabled");
  });


  /*
  ----------------------------------------------------------------------------------
  FIXED NAV FUNCTIONALITY
  */

  window.addEventListener("scroll", () => {
    if (window.scrollY > 39) {
      document.querySelector(".header__bottom-nav").classList.add("is-fixed");
    } else {
      document
        .querySelector(".header__bottom-nav")
        .classList.remove("is-fixed");
    }
  });

  /*
  ----------------------------------------------------------------------------------
  NAV FUNCTIONALITY
  */

  let navLinks = document.querySelectorAll(".menu-item-has-children a");
  if (navLinks) {
    navLinks.forEach((navLink) => {
      navLink.addEventListener("click", (e) => {
        let parent = e.target.parentElement;
        let dropdownButton = parent.querySelector('a[role="button"]');
        let subMenuWrap = parent.querySelector(".sub-menu-wrap");
        if (parent.classList.contains("menu-item-has-children")) {
          e.preventDefault();

          // Check if the clicked menu item is already active
          const isActive = parent.classList.contains("active");

          // Remove 'active' class from all menu items
          document
            .querySelectorAll(".menu-item-has-children")
            .forEach((menuItem) => {
              let button = menuItem.querySelector("a");
              button.setAttribute("aria-expanded", "false");
              menuItem.classList.remove("active");
              const submenuWrap = menuItem.querySelector(".sub-menu-wrap");
              if (submenuWrap) {
                submenuWrap.classList.remove("active");
                submenuWrap.style.display = "none"; // Hide all submenus
              }
            });

          // If the clicked menu item was not already active, add 'active' class
          if (!isActive) {
            parent.classList.add("active");
            dropdownButton.setAttribute("aria-expanded", "true");
            $(subMenuWrap).toggleClass("active");
            $(subMenuWrap).slideToggle();
          }
        }
      });
    });
  }
  let dropDownLinks = document.querySelectorAll(".menu-item-has-children > a");
  dropDownLinks.forEach((link) => {
    link.setAttribute("aria-haspopup", "true");
    link.setAttribute("aria-expanded", "false");
  });
  /*
  ----------------------------------------------------------------------------------
  HEADLINE VIDEO FUNCTIONALITY
  */

  $("#video").click(function () {
    $("iframe").each(function () {
      $(this)[0].contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    });
    let $link = $(this).data("video");

    if ($link.indexOf("?") > -1) {
      $link = $link + "&autoplay=1";
    } else {
      $link = $link + "?autoplay=1&enablej=1";
    }
    let $poster = $("#video .headline-video__poster");
    let $playButton = $("#video .headline-video__play");
    let $iframe = `<iframe id="iframe" src="${$link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; muted; playsinline;encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`;

    $poster.hide();
    $playButton.hide();
    $(this).append($iframe);
  });

  $("#video-copy").click(function () {
    $("iframe").each(function () {
      $(this)[0].contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    });
    let $link = $(this).data("video");

    if ($link.indexOf("?") > -1) {
      $link = $link + "&autoplay=1";
    } else {
      $link = $link + "?autoplay=1&enablej=1";
    }
    let $poster = $("#video-copy .headline-video__poster");
    let $playButton = $("#video-copy .headline-video__play");
    let $iframe = `<iframe id="iframe" src="${$link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; muted; playsinline;encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`;

    $poster.hide();
    $playButton.hide();
    $(this).append($iframe);
  });

  /*
  ----------------------------------------------------------------------------------
  EDUCATION VIDEO FUNCTIONALITY
  */

  $(".education-video").on("click", function () {
    $("iframe").each(function () {
      $(this)[0].contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    });
    let $link = $(this).data("video");

    if ($link.indexOf("?") > -1) {
      $link = $link + "&autoplay=1";
    } else {
      $link = $link + "?autoplay=1&enablejs=1";
    }
    let $poster = $(this).find(".headline-video__poster");
    let $playButton = $(this).find(".headline-video__play");
    let $iframe = `<iframe src="${$link}" frameborder="0" allow="scrolling, accelerometer; autoplay; clipboard-write; muted; playsinline;encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`;

    $poster.hide();
    $playButton.hide();
    $(this).append($iframe);
  });

  /*
  ----------------------------------------------------------------------------------
  WHY BAROSTIM ANIMATION MODAL
  */
  $("#how-barostim-works").on("click", function () {
    $this = $(this);
    let $link = $this.data("video");
    if ($link.indexOf("?") > -1) {
      $link = $link + "&autoplay=1";
    } else {
      $link = $link + "?autoplay=1&enablej=1";
    }
    $("#why-barostim-iframe").attr("src", $link);

    $("#why-barostim-modal").addClass("active");
  });

  $(".why-barostim-modal .why-barostim-close").click(function () {
    $("#why-barostim-iframe").attr("src", "");
    $("#why-barostim-modal").removeClass("active");
  });

  $(".why-barostim-modal .modal-overlay").click(function () {
    $("#why-barostim-iframe").attr("src", "");
    $("#why-barostim-modal").removeClass("active");
  });

  /*
  ----------------------------------------------------------------------------------
  PATIENT STORIES MODALS
  */

  $(".story--video").on("click", function () {
    let $this = $(this);
    let $link = $this.data("video");
    if ($link.indexOf("?") > -1) {
      $link = $link + "&autoplay=1";
    } else {
      $link = $link + "?autoplay=1&enablej=1";
    }
    $("#patient-stories-iframe").attr("src", $link);

    $("#patient-stories-modal").addClass("active");
  });

  $(".patient-stories-modal .patient-stories-close").click(function () {
    $("#patient-stories-iframe").attr("src", "");
    $("#patient-stories-modal").removeClass("active");
  });

  $(".patient-stories-modal .modal-overlay").click(function () {
    $("#patient-stories-iframe").attr("src", "");
    $("#patient-stories-modal").removeClass("active");
  });

  /*
  ----------------------------------------------------------------------------------
  DEVICE ANIMATION ON GETTING BAROSTIM PAGE
  */

  function changeOpacity(front, back) {
    $("#animation-image-front").css("opacity", front);
    $("#animation-image-back").css("opacity", back);
  }

  function switchOpacity(opacity) {
    switch (opacity) {
      case "0":
        changeOpacity("0.2", "1");
        break;
      case "0.2":
        changeOpacity("0.2", "1");
        break;
      case "0.4":
        changeOpacity("0.4", "0.8");
        break;
      case "0.6":
        changeOpacity("0.6", "0.6");
        break;
      case "0.8":
        changeOpacity("0.8", "0.4");
        break;
      case "1":
        changeOpacity("1", "0.2");
        break;
      default:
        changeOpacity("0.2", "1");
        break;
    }
  }

  $("#opacity-controller").bind("input change", function () {
    let $opacity = $(this).val();
    switchOpacity($opacity);
  });

  /*
  ----------------------------------------------------------------------------------
  ABOUT BAROSTIM VIDEO MODAL
  */
  $("#barostim-video-thumbnail, #barostim-video").click(function () {
    $("#modal-barostim-video-thumbnail").addClass("active");
    $("body").addClass("no-scroll");
  });
  $("#modal-close").click(function () {
    $("#modal-barostim-video-thumbnail").removeClass("active");
    $("body").removeClass("no-scroll");
    let src = $("#two-col-vid-iframe").attr("src");
    $("#two-col-vid-iframe").attr("src", src);

    $("#barostim-video-thumbnail")[0].scrollIntoView();
    window.scrollBy(0, -160);
  });

  /*
  ----------------------------------------------------------------------------------
  LOAD MORE NEWSROOM ITEMS
  */
  $(function () {
    $(".newsroom__column").slice(0, 9).show();
    $("#load-more").bind("click", function (e) {
      e.preventDefault();
      $(".newsroom__column:hidden").slice(0, 9).slideDown();
      if ($(".newsroom__column:hidden").length == 0) {
        $("#load-more").fadeOut("slow");
        $("#to-top").addClass("d-block").fadeIn("slow");
      }
    });
  });

  /*
  ----------------------------------------------------------------------------------
  LOAD MORE PUBLICATIONS
  */
  $(function () {
    $(".publication__column").slice(0, 8).show();
    $("#more-publications").bind("click", function (e) {
      e.preventDefault();
      $(".publication__column:hidden").slice(0, 8).slideDown();
      if ($(".publication__column:hidden").length == 0) {
        $("#more-publications").fadeOut("slow");
      }
    });
  });

  /*
  ----------------------------------------------------------------------------------
  TEAM MEMBER MODALS
  */
  $(".bio_button--image, .team_member--image img, .team_member--name").click(
    function () {
      let $this = $(this);
      let $link = $this.data("id");
      $("#modal-" + $link).addClass("active");
      $("body").addClass("no-scroll");
    }
  );

  $(".team_modal .modal__close").click(function () {
    let $this = $(this);
    $($this.closest(".team_modal")).removeClass("active");
    $("body").removeClass("no-scroll");
  });

  $(".team_modal .modal-overlay").click(function () {
    let $this = $(this);
    $($this.closest(".team_modal")).removeClass("active");
    $("body").removeClass("no-scroll");
  });
  /*
  ----------------------------------------------------------------------------------
  TEAM MEMBER MODALS CAROUSEL FUNCTION
  */
  $(".team_modal").each(function () {
    var currentModal = $(this);

    //click next
    currentModal.find(".btn-next").click(function () {
      currentModal.removeClass("active");
      currentModal
        .closest(".team_modal")
        .nextAll(".team_modal")
        .first()
        .addClass("active");
      $("body").removeClass("no-scroll");
    });

    //click prev
    currentModal.find(".btn-prev").click(function () {
      currentModal.removeClass("active");
      currentModal
        .closest(".team_modal")
        .prevAll(".team_modal")
        .first()
        .addClass("active");
      $("body").removeClass("no-scroll");
    });
  });
  /*
  ----------------------------------------------------------------------------------
  TEAM MEMBER ACCORDIONS
  */
  $(".accordion-item .accordion-icon").bind("click", function (e) {
    e.preventDefault();
    // Add the correct active class
    if ($(this).closest(".accordion-item").hasClass("active")) {
      // Remove active classes
      $(".accordion-item").removeClass("active");
      $(this).removeClass("rotate");
    } else {
      // Remove active classes
      $(".accordion-item").removeClass("active");

      // Add the active class
      $(this).closest(".accordion-item").addClass("active");
      $(this).addClass("rotate");
    }

    // Show the content
    var $content = $(this).next();
    $content.slideToggle(100);
    $(".accordion-item .accordion-content").not($content).slideUp("fast");
  });

  /*
  ----------------------------------------------------------------------------------
  SEARCH
  */

  $("#mobile-search-form").submit(function (e) {
    e.preventDefault();
    let searchTerm = $("#mobile-search-form input").val();
    let searchTerms = searchTerm.split(" ").join("+");
    let searchUrl = `/?s=${searchTerms}`;
    $("#mobile-search-form input").val("");
    window.location.href = searchUrl;
  });

  $("#desktop-nav-search").click(function (e) {
    $(this).toggleClass("active");
    $("#desktop-search").toggleClass("active");
    $("#top-menu__list--bottom").toggleClass("hidden");
    $("#top-menu__list--bottom li:not(.menu-header-search)").toggleClass(
      "hidden"
    );
  });

  $("#desktop-search").submit(function (e) {
    e.preventDefault();
    let searchTerm = $("#desktop-search input").val();
    let searchTerms = searchTerm.split(" ").join("+");
    let searchUrl = `/?s=${searchTerms}`;
    $("#desktop-search input").val("");
    $("#desktop-nav-search").removeClass("active");
    $("#desktop-search").removeClass("active");
    $("#top-menu__list--bottom").removeClass("hidden");
    window.location.href = searchUrl;
  });

  /*
  ----------------------------------------------------------------------------------
  CAREER BENEFIT POPUPS
  */

  // Open Popup
  $(".popup-icon, .benefit__icon").click(function () {
    $(".popup-open").removeClass("popup-open");
    $(this).parent().addClass("popup-open");
  });

  // Close Popup
  $(".popup-close, .benefit__icon--card .popup-content").click(function () {
    $(".popup-open").removeClass("popup-open");
    $(this).next(".benefit__icon--caption").show();
  });

  /* Ambassador form popup */
  // $(".ambassador-form").click(function () {
  //   $(this).parent().addClass("popup-open");
  // });

  /* Contact Fixed Button Scroll Offset  */
  $(".fixed-button-link").click(function () {
    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top + -200,
      },
      100
    );
  });
  $(".page-template-page-barostim .section--slider-hero .orange-button").click(
    function () {
      if ($(window).width() > 650) {
        $("html, body").animate(
          {
            scrollTop: $($.attr(this, "href")).offset().top + -300,
          },
          100
        );
      }
    }
  );
  /* Call jobs API if on careers page*/
  // if (location.pathname.indexOf("careers") != -1) {
  //   let careersEl = document.getElementById("careers_holder");
  //   fetch("https://boards-api.greenhouse.io/v1/boards/cvrx/jobs")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.jobs === null) {
  //         careersEl.innerHTML = `<p>No jobs available. Check back regularly for updated listings.`;
  //       } else {
  //         careersEl.innerHTML = data.jobs
  //           .map(
  //             (job) =>
  //               `<div class="job__card">
  //               <h4>${job.title}</h4>
  //               <span>${job.location.name}</span>
  //               <a href="${job.absolute_url}" target="_blank" class="orange-button offsite-link">Apply</a>
  //               </div>`
  //           )
  //           .join("");
  //         let offsite_links = document.querySelectorAll(".offsite-link");
  //         offsite_links.forEach((el) => {
  //           el.addEventListener("click", function (e) {
  //             const href =
  //               this.getAttribute("href") || this.getAttribute("data-href");

  //             if (!href || /^(mailto:|tel:|#)/.test(href)) {
  //               return;
  //             }

  //             const tempAnchor = document.createElement("a");
  //             tempAnchor.href = href;
  //             const currentDomain = window.location.hostname;

  //             if (
  //               tempAnchor.hostname !== "" &&
  //               tempAnchor.hostname !== currentDomain
  //             ) {
  //               e.preventDefault();
  //               updateSpeedbumpLink(href);
  //               showModal(speedbumpElement);
  //             }
  //           });
  //         });
  //       }
  //     });
  // }
  /* SLICK SLIDER INITIALIZATION */

  /* Initialize each main slider */
  var numSlick = 0;
  $(".slider-single:not(.slider-patient)").each(function () {
    numSlick++;

    $(this)
      .addClass("slider-" + numSlick)
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: ".slider-nav.slider-" + numSlick,
        adaptiveHeight: true,
        infinite: true,
        useTransform: true,
        speed: 400,
        cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
        accessibility: true,
      });
  });
  /* Initialize slider specific to Credent registration page*/
  var numSlick = 0;
  $(".slider-single.slider-patient").each(function () {
    numSlick++;

    $(this)
      .addClass("slider-" + numSlick)
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: ".slider-nav.slider-" + numSlick,
        adaptiveHeight: true,
        infinite: true,
        useTransform: true,
        speed: 400,
        cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
        accessibility: true,
      });
  });
  /* Initialize each nav slider, reset slick counter */
  numSlick = 0;
  $(".slider-nav:not(.slider-patient)").each(function () {
    numSlick++;
    $(this)
      .addClass("slider-" + numSlick)
      .slick({
        accessibility: true,
        vertical: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: ".slider-single.slider-" + numSlick,
        arrows: true,
        focusOnSelect: true,
        prevArrow:
          "<img class='a-left control-c prev slick-prev' aria-label='go to previous slide' role='button' src='/wp-content/themes/cvrx/assets/images/slick-prev.svg'><p class='prev-text'>Previous<br/>Video</p>",
        nextArrow:
          "<img class='a-right control-c next slick-next' aria-label='go to next slide' role='button' src='/wp-content/themes/cvrx/assets/images/slick-next.svg'><p class='next-text'>Next<br/>Video</p>",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  });
  /* Initialize nav slider specific to registration page */
  numSlick = 0;
  $(".slider-nav.slider-patient").each(function () {
    numSlick++;
    $(this)
      .addClass("slider-" + numSlick)
      .slick({
        accessibility: true,
        vertical: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: ".slider-single.slider-" + numSlick,
        arrows: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      });
  });

  /* On change/click functions to sync sliders together */
  $(".slider-single").bind(
    "afterChange",
    function (event, slick, currentSlide) {
      /* stop any video playing */
      $("iframe").each(function () {
        $(this)[0].contentWindow.postMessage(
          '{"event":"command","func":"' + "stopVideo" + '","args":""}',
          "*"
        );
      });
      $(this).next(".slider-nav").slick("slickGoTo", currentSlide);
      var currrentNavSlideElem =
        '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
      $(".slider-nav .slick-slide.is-active").removeClass("is-active");
      $(currrentNavSlideElem).addClass("is-active");
    }
  );

  $(".slider-nav").bind("click", ".slick-slide", function (event) {
    /* stop any video playing */
    $("iframe").each(function () {
      $(this)[0].contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    });
    event.preventDefault();
    var goToSingleSlide = $(this).data("slick-index");
    $(this).prev(".slider-single").slick("slickGoTo", goToSingleSlide);
  });

  /* Initialize Credent registration page slider */
  $(".slider-testimonials").slick({
    fade: true,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    accessibility: true,
  });

  /* OFF-SITE LINK INTERSTITIAL */

  const speedbumpElement = $("#speedbump");
  const speedbumpLink = document.querySelector("#speedbump-link");

  // Helper to extract simple domain url for text purposes
  const extractDomainName = (url) => {
    const domainRegex = /https?:\/\/(?:www\.)?([^/]+)/;
    const match = url.match(domainRegex);

    if (match) {
      return match[1];
    } else {
      return "Domain name not found";
    }
  };
  // Helper to update the href of #speedbump-link
  const updateSpeedbumpLink = (newHref) => {
    if (speedbumpLink) {
      speedbumpLink.setAttribute("href", newHref);

      let linkTitle = extractDomainName(newHref);

      document.getElementById("site-name").innerText = linkTitle;
    }
  };

  // Helper to show modal
  const showModal = (element) => {
    if (element) {
      speedbumpElement.addClass("active");
    } else {
      console.warn("Modal element not found in DOM");
    }
  };
  // Grab all links that are NOT the outbound modal link
  const links = document.querySelectorAll(
    "a:not(#speedbump-link), button:not(#speedbump-link)"
  );

  // Reset speedbump link when modal is closed
  if (speedbumpElement) {
    $(".modal-dismiss").each(function () {
      var close_modal_link = this;
      close_modal_link.addEventListener("click", function () {
        speedbumpElement.removeClass("active");
        updateSpeedbumpLink("");
      });
    });
  } else {
    console.warn("Modal element not found in DOM");
  }
  // Add event listener to all non-modal links. Grab href, compare to host.
  // If not a match, show interstitial and update modal outbound link.
  links.forEach((el) => {
    el.addEventListener("click", function (e) {
      const href = this.getAttribute("href") || this.getAttribute("data-href");

      if (!href || /^(mailto:|tel:|#)/.test(href)) {
        return;
      }

      const tempAnchor = document.createElement("a");
      tempAnchor.href = href;
      const currentDomain = window.location.hostname;
      // Check for EU domains
      const currentDomainAndEU = [
        currentDomain,
        new RegExp('^(cvrx\.(?!com$).+)$'), // matches any domain that starts with 'cvrx.' except 'cvrx.com'
        new RegExp('^cvrx\w+(?!stg\.wpengine\.com$|stg\.wpenginepowered\.com$|1dev\.wpengine\.com$|1dev\.wpenginepowered\.com$)\.wpengine\.com$'), // matches any domain that starts with 'cvrx', followed by any word, then '.wpengine.com' except the ones in the exclusion list
        new RegExp('^cvrx\w+(?!stg\.wpengine\.com$|stg\.wpenginepowered\.com$|1dev\.wpengine\.com$|1dev\.wpenginepowered\.com$)\.wpenginepowered\.com$') // matches any domain that starts with 'cvrx', followed by any word, then '.wpenginepowered.com' except the ones in the exclusion list
      ];

      if (!currentDomainAndEU.some(domain => domain instanceof RegExp ? domain.test(tempAnchor.hostname.replace(/www\./g, '')) : domain === tempAnchor.hostname.replace(/www\./g, ''))) {
        e.preventDefault();
        updateSpeedbumpLink(href);
        showModal(speedbumpElement);
      }
    });
  });

  // Accessibility
  let slideArrows = document.querySelectorAll(".control-c");
  slideArrows.forEach((arrow) => {
    arrow.setAttribute("tabindex", "0");
  });
  // Add aria-labels to outbound footer links
  let outboundLinks = document.querySelectorAll('a[target="_blank"]');
  outboundLinks.forEach((link) => {
    link.setAttribute("aria-label", "Opens in a new tab");
  });

  let headings = document.querySelectorAll(
    ".slider-hero__text-block p, .patient-quote p, .patient-id__caption p"
  );
  headings.forEach((heading) => {
    heading.setAttribute("role", "heading");
  });

  /* ----------------------------------------------------------------------------------
  END DOC READY
  */
});

window.onload = function () {
  const barostimCentersInEurope = document.getElementById("barostimCentersInEurope");

  if (barostimCentersInEurope) {
    console.log('barostimCentersInEurope found');
    barostimCentersInEurope.onload = function() {
      console.log('barostimCentersInEurope loaded');
      const doc = barostimCentersInEurope.contentDocument || barostimCentersInEurope.contentWindow.document;
      const style = `
            <style>
                .SfQLQb-dIxMhd-bN97Pc-b3rLgd.SfQLQb-QClCJf-giiMnc.neVct-IlgHgd .i4ewOd-pzNkMb-QClCJf-giiMnc-V1ur5d-haAclf {
                    display: none !important;
                }
            </style>
        `;
      doc.head.insertAdjacentHTML('beforeend', style);
    };
  }
};
