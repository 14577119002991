/*
----------------------------------------------------------------------------------
HERO SLIDER FUNCTIONALITY - SWIPER.js
*/
import jQuery from "jquery";
import Swiper, { Autoplay, Navigation, Pagination, A11y } from "swiper";

jQuery(function ($) {
  Swiper.use([Autoplay, Navigation, Pagination, A11y]);

  const swiper = new Swiper(".swiper", {
    // Optional parameters
    direction: "horizontal",
    loop: false,
    shortSwipes: false,
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
    a11y: true,
    paginationBulletMessage: "Go to slide {{index}}",
  });
});
