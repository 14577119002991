const jQuery = require('jquery');

jQuery(function ($) {
  //  globals for specific screens:
  let $wrapper = $(".questionnaire-wrapper");
  let $formScreen = $('.questionnaire-screen[data-question="form"]').data(
    "screen"
  );
  let $bpScreen = $('.questionnaire-screen[data-question="bp"]').data("screen");
  let $implantScreen = $('.questionnaire-screen[data-question="implant"]').data(
    "screen"
  );
  let $efScreen = $('.questionnaire-screen[data-question="ef"]').data("screen");

  let $lvadScreen = $('.questionnaire-screen[data-question="lvad"]').data(
    "screen"
  );
  $(
    ".questionnaire-response-button:not(.questionnaire-insurance-button)"
  ).click(function (e) {
    e.preventDefault();

    let $this = $(this);
    let $screen = $this.data("screen");
    let $nextScreen = $screen + 1;
    let $response = $this.data("response");
    let $nextQuestion = $(
      '.questionnaire-screen[data-screen="' + $nextScreen + '"]'
    ).data("question");
    // If the answer is yes and we are on the implant screen, skip to the lvad screen
    if (
      ($response === "yes" && $screen == $implantScreen) ||
      ($response === "yes" && $screen == $efScreen) ||
      ($response === "no" && $screen == $bpScreen)
    ) {
      let $question = $("#questionnaire-screen-" + $lvadScreen).data(
        "question"
      );
      $("#questionnaire-screen-" + $screen).removeClass("active");
      $("#questionnaire-screen-" + $lvadScreen).addClass("active");
      $(".questionnaire-wrapper").attr("data-wrapper", $question);
      $("#circle-" + $screen).removeClass("active");
      $("#circle-" + $lvadScreen).addClass("active");
    }
    // BP screen settings
    if ($response === "yes" && $screen == $bpScreen) {
      // console.log("On BP screen", $response, $bpScreen);

      let $question = $("#questionnaire-screen-" + $formScreen).data(
        "question"
      );
      // console.log($formScreen);
      $("#questionnaire-screen-" + $screen).removeClass("active");
      $("#questionnaire-screen-" + $formScreen).addClass("active");
      $(".questionnaire-wrapper").attr("data-wrapper", $question);
      $("#circle-" + $screen).removeClass("active");
      $("#circle-" + $formScreen).addClass("active");
      $wrapper.addClass("unqual");
      //set text on form screen based on qualified/disqualified
      $(".not-qualified").addClass("active");
      $(".qualified").removeClass("active");

      $(".questionnaire-form.not-qualified").addClass("active");
      $(".questionnaire-form.qualified").removeClass("active");
    }

    // if ($response === "no" && $screen == $bpScreen) {
    //   $("#questionnaire-screen-" + $screen).removeClass("active");
    //   $("#questionnaire-screen-" + $lvadScreen).addClass("active");
    //   $wrapper.removeClass("unqual");

    //   $(".questionnaire-wrapper").attr("data-wrapper", $nextQuestion);
    //   $("#circle-" + $screen).removeClass("active");
    //   $("#circle-" + $lvadScreen).addClass("active");
    //   //set text on form screen based on qualified/disqualified
    //   $(".not-qualified").removeClass("active");
    //   $(".qualified").addClass("active");
    //   $(".questionnaire-form.not-qualified").removeClass("active");
    //   $(".questionnaire-form.qualified").addClass("active");
    // }

    // If any answer is no (or yes, in the case of lvad and bp screens):
    if (
      ($response == "no" &&
        $screen !== $implantScreen &&
        $screen !== $bpScreen) ||
      ($response == "yes" && $screen == $lvadScreen) ||
      ($response == "yes" && $screen == $bpScreen)
    ) {
      let $question = $("#questionnaire-screen-" + $formScreen).data(
        "question"
      );
      $("#questionnaire-screen-" + $screen).removeClass("active");
      $("#questionnaire-screen-" + $formScreen).addClass("active");
      $(".questionnaire-wrapper").attr("data-wrapper", $question);
      $("#circle-" + $screen).removeClass("active");
      $("#circle-" + $formScreen).addClass("active");
      $wrapper.addClass("unqual");
      //set text on form screen based on qualified/disqualified
      $(".not-qualified").addClass("active");
      $(".qualified").removeClass("active");

      $(".questionnaire-form.not-qualified").addClass("active");
      $(".questionnaire-form.qualified").removeClass("active");
      $(".questionnaire-wrapper").addClass("wrapper-not-qualified");
      $(".questionnaire-wrapper").removeClass("wrapper-qualified");
      //show submit <button disabled="disabled"></button>
      // $(".questionnaire-submit-button").addClass("active");
      // $("#questionnaire-submit").attr("data-qualified", "not-qualified");
      // add hubspot tag to send to correct list
      // $(
      //   '.questionnaire-form input[name="questionnaire_qualified_disqualified"]'
      // ).val("Disqualified");
    }
    // If answer is yes & is not insurance screen:
    if (
      ($response !== "no" &&
        $screen !== $bpScreen &&
        $screen !== $implantScreen &&
        $screen !== $efScreen &&
        $screen !== $lvadScreen) ||
      ($response == "no" && $screen == $implantScreen) ||
      ($response == "don't know" && $screen == $efScreen) ||
      ($response == "no" && $screen == $lvadScreen)
    ) {
      $("#questionnaire-screen-" + $screen).removeClass("active");
      $("#questionnaire-screen-" + $nextScreen).addClass("active");
      $wrapper.removeClass("unqual");

      $(".questionnaire-wrapper").attr("data-wrapper", $nextQuestion);
      $("#circle-" + $screen).removeClass("active");
      $("#circle-" + $nextScreen).addClass("active");
      //set text on form screen based on qualified/disqualified
      $(".not-qualified").removeClass("active");
      $(".qualified").addClass("active");
      $(".questionnaire-form.not-qualified").removeClass("active");
      $(".questionnaire-form.qualified").addClass("active");
      $(".questionnaire-wrapper").addClass("wrapper-qualified");
      $(".questionnaire-wrapper").removeClass("wrapper-not-qualified");
      // $("#questionnaire-submit").attr("data-qualified", "qualified");
      // add hubspot tag to send to correct list
      $(
        '.questionnaire-form input[name="questionnaire_qualified_disqualified"]'
      ).val("Qualified");
    }
  }); //questionnaire-response-button click

  // $(".questionnaire-insurance-button").click(function (e) {
  //   e.preventDefault();
  //   let $this = $(this);
  //   let $response = $this.data("response");
  //   let $screen = $this
  //     .parents(".questionnaire-response-buttons")
  //     .parents(".questionnaire-screen")
  //     .data("screen");

  //   let $question = $("#questionnaire-screen-" + $formScreen).data("question");

  //   $("#questionnaire-screen-" + $screen).removeClass("active");
  //   $("#questionnaire-screen-" + $formScreen).addClass("active");
  //   $(".questionnaire-wrapper").attr("data-wrapper", $question);
  //   $("#circle-" + $screen).removeClass("active");
  //   $("#circle-" + $formScreen).addClass("active");
  //set text on form screen based on qualified/disqualified
  // $(".qualified").addClass("active");
  // $(".not-qualified").removeClass("active");

  //show submit <button disabled="disabled"></button>
  // $(".questionnaire-submit-button").addClass("active");
  // add hubspot tag to send to correct list
  //   $(
  //     '.questionnaire-form input[name="questionnaire_qualified_disqualified"]'
  //   ).val("Qualified");
  // }); //questionnaire-insurance-button click

  //validate common fields in embed form
  // const validateFields = () => {
  //   let $firstName = $('.questionnaire-form.active input[name="firstname"]')[0];
  //   let $lastName = $('.questionnaire-form.active input[name="lastname"]')[0];
  //   let $zip = $('.questionnaire-form.active input[name="zip"]')[0];

  // Text inputs
  // let $inputs = [$firstName, $lastName, $zip];

  // let $inputsCount = $inputs.length;
  //get empty text inputs
  // let $notEmpty = $inputs.filter(function (input) {
  //   return (
  //     input.value !== "" && input.value !== null && input.value !== undefined
  //   );
  // });

  // let $empty = $inputs.filter(function (input) {
  //   return (
  //     input.value === "" || input.value === null || input.value === undefined
  //   );
  // });
  //show error messages if any text inputs are empty
  //   if ($notEmpty.length < $inputsCount) {
  //     for (let i = 0; i < $empty.length; i++) {
  //       $empty[i].classList.add("error");
  //     }
  //     $(".questionnaire-submit-button .questionnaire-error-message").addClass(
  //       "active"
  //     );

  //     return false;
  //   }
  //   return true;
  // }; //validateFields

  //Validate email in form embed

  // function validateEmail() {
  //   let em = $('.questionnaire-form.active input[name="email"]')[0];
  //   let pattern = /^\w+([-+.'][^\s]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  //   let testEmail = pattern.test(em.value);

  //   if (testEmail) {
  //     return true;
  //   }
  //   $(em).addClass("error");
  //   $(".questionnaire-error-message").addClass("active");
  //   return false;
  // }

  //validate phone number in form embed
  // function validatePhone() {
  //   let phone = $('.questionnaire-form.active input[name="phone"]')[0];
  //   let pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  //   let testPhone = pattern.test(phone.value);

  //   if (testPhone) {
  //     return true;
  //   }
  //   $(phone).addClass("error");
  //   $(".questionnaire-error-message").addClass("active");
  //   return false;
  // }

  // $(document).on("click", '[data-qualified="qualified"]', function (e) {
  //   e.preventDefault();
  /*
VALIDATE FORM IF PERSON IS QUALIFIED
*/

  // const validateMobile = () => {
  //   if ($(".consent-get-mobile").hasClass("active")) {
  //     let $mobile = $(
  //       '.questionnaire-form.active input[name="mobile-number"]'
  //     )[0];
  //     let $regEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  //     let $valid = $regEx.test($mobile.value);
  //     if (!$valid) {
  //       $("#mobile-number-error").addClass("active");
  //       return false;
  //     }
  //     $('input[name="mobilephone"]').val($mobile.value);
  //     $('input[name="consent_to_text"]').val("Yes");
  //     return true;
  //   }
  // }; //validateMobile

  // const validateShareConsent = () => {
  //   let $consent = $('input[name="share-consent-not"]:checked').val();

  //   if (
  //     $('.questionnaire-form input[name="share-consent"]:checked').val() !==
  //     "yes"
  //   ) {
  //     $("#consent-to-share").addClass("active");

  //     return false;
  //   }

  //   return true;
  // }; //validateShareConsent

  // const validateRadios = () => {
  //   let $rad = document.querySelector('input[name="text-consent"]:checked');
  //   if ($rad === null || $rad === undefined) {
  //     $('input[name="consent_to_text"]').val("No");
  //     return true;
  //   }

  //   if ($rad.value === "no") {
  //     $('input[name="consent_to_text"]').val("No");
  //     return true;
  //   }

  //   if ($rad.value === "yes") {
  //     if ($(".consent-get-mobile").hasClass("active")) {
  //       return validateMobile();
  //     }
  //   }
  //   return true;
  // }; //validateRadios

  // if (
  //   validateFields() &&
  //   validatePhone() &&
  //   validateEmail() &&
  //   validateRadios() &&
  //   validateShareConsent()
  // ) {
  //remove fake consent, fake submit button & circles before showing thank you screen
  // $(".questionnaire-form .questionnaire-consent").hide();
  // $(".questionnaire-submit-button").hide();
  // $(".questionnaire-circles").hide();
  // $(".error-msg").removeClass("active").hide();
  // set data-wrapper to last screen
  // $(".questionnaire-wrapper").attr("data-wrapper", "thank-you");

  //submit the form
  //     $(".questionnaire-form.qualified input[type='submit']").trigger("click");
  //   }
  // }); // click questionnaire-fake-submit Qualified

  // $(document).on("click", '[data-qualified="not-qualified"]', function (e) {
  //   e.preventDefault();

  /*
VALIDATE FORM IF PERSON IS NOT QUALIFIED
*/
  //   const validateMobileNotQualified = () => {
  //     if ($(".consent-get-mobile").hasClass("active")) {
  //       let $mobile = $(
  //         '.questionnaire-form.active input[name="mobile-number-not"]'
  //       )[0];
  //       let $regEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  //       let $valid = $regEx.test($mobile.value);
  //       if (!$valid) {
  //         $("#mobile-number-error").addClass("active");

  //         return false;
  //       }
  //       $('input[name="mobilephone"]').val($mobile.value);
  //       $('input[name="consent_to_text"]').val("Yes");
  //       return true;
  //     }
  //   }; //validateMobileNotQualified

  //   const validateShareConsentNotQualified = () => {
  //     let $consent = $('input[name="share-consent-not"]:checked').val();

  //     if (
  //       $(
  //         '.questionnaire-form input[name="share-consent-not"]:checked'
  //       ).val() !== "yes"
  //     ) {
  //       $("#consent-to-share-not").addClass("active");

  //       return false;
  //     }

  //     return true;
  //   }; //validateShareConsentNotQualified

  //   const validateRadiosNotQualified = () => {
  //     let $rad = document.querySelector(
  //       'input[name="text-consent-not"]:checked'
  //     );
  //     if ($rad === null || $rad === undefined) {
  //       $('input[name="consent_to_text"]').val("No");
  //       return true;
  //     }

  //     if ($rad.value === "no") {
  //       $('input[name="consent_to_text"]').val("No");
  //       return true;
  //     }

  //     if ($rad.value === "yes") {
  //       if ($(".consent-get-mobile").hasClass("active")) {
  //         return validateMobileNotQualified();
  //       }
  //     }
  //     return true;
  //   }; //validateRadiosNotQualified

  //   if (
  //     validateFields() &&
  //     validatePhone() &&
  //     validateEmail() &&
  //     validateRadiosNotQualified() &&
  //     validateShareConsentNotQualified()
  //   ) {
  //     //remove fake consent, fake submit button & circles before showing thank you screen
  //     $(".questionnaire-form .questionnaire-consent").hide();
  //     $(".questionnaire-submit-button").hide();
  //     $(".questionnaire-circles").hide();
  //     $(".error-msg").removeClass("active").hide();
  //     // set data-wrapper to last screen
  //     $(".questionnaire-wrapper").attr("data-wrapper", "thank-you");

  //     //submit the form
  //     $(".questionnaire-form.not-qualified input[type='submit']").trigger(
  //       "click"
  //     );
  //   }
  // }); // click questionnaire-fake-submit NOT Qualified

  //change events for error messages on form screen
  // let windowWidth = $(window).width();

  // $('input[type="radio"]').on("change", function (e) {
  //   $(".questionnaire-form .error-msg").removeClass("active");
  // });

  // $('input[name="is-mobile"]').on("change", function (e) {
  //   if ($(this).val() == "no") {
  //     $(".consent-get-mobile").addClass("active");
  //     if (windowWidth > 1366) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "955");
  //     }
  //     if (windowWidth > 1249 && windowWidth <= 1366) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1320");
  //     }
  //     if (windowWidth > 1179 && windowWidth <= 1249) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1350");
  //     }
  //     if (windowWidth > 479 && windowWidth <= 1179) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1500");
  //     }
  //     if (windowWidth > 389 && windowWidth <= 479) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1600");
  //     }
  //   }
  //   if ($(this).val() == "yes") {
  //     $(".consent-get-mobile").removeClass("active");
  //   }
  // });

  // $('input[name="is-mobile-not"]').on("change", function (e) {
  //   if ($(this).val() == "no") {
  //     $(".consent-get-mobile").addClass("active");
  //     if (windowWidth > 1366) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "955");
  //     }
  //     if (windowWidth > 1249 && windowWidth <= 1366) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1320");
  //     }
  //     if (windowWidth > 1179 && windowWidth <= 1249) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1350");
  //     }
  //     if (windowWidth > 479 && windowWidth <= 1179) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1500");
  //     }
  //     if (windowWidth > 389 && windowWidth <= 479) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1600");
  //     }
  //   }

  //   if ($(this).val() == "yes") {
  //     $(".consent-get-mobile").removeClass("active");
  //   }
  // });

  // $('input[name="is-mobile-not"]').on("change", function (e) {
  //   if ($(this).val() == "yes") {
  //     $(".consent-get-mobile").removeClass("active");
  //   }
  // });

  // $('input[name="text-consent"]').on("change", function (e) {
  //   if ($(this).val() == "yes") {
  //     $(".consent-is-mobile").addClass("active");
  //     if (windowWidth > 479 && windowWidth <= 1179) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1500");
  //     }
  //     if (windowWidth > 359 && windowWidth <= 479) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1650");
  //     }
  //   }
  //   if ($(this).val() == "no") {
  //     $(".consent-is-mobile").removeClass("active");
  //   }
  // });

  // $('input[name="text-consent-not"]').on("change", function (e) {
  //   if ($(this).val() == "yes") {
  //     $(".consent-is-mobile").addClass("active");
  //     if (windowWidth > 479 && windowWidth <= 1179) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1500");
  //     }
  //     if (windowWidth > 359 && windowWidth <= 479) {
  //       $('.questionnaire-wrapper[data-wrapper="form"]').css("height", "1650");
  //     }
  //   }
  //   if ($(this).val() == "no") {
  //     $(".consent-is-mobile").removeClass("active");
  //   }
  // });

  // $(document).keyup(".hs-input", function (e) {
  //   $(".questionnaire-form .error-msg").removeClass("active");
  //   $(".questionnaire-error-message").removeClass("active");
  // });

  // $(document).keyup(".questionnaire-form.active input", function (e) {
  //   e.target.classList.remove("error");
  // });

  /**
   * END DOC READY */
});
