const jQuery = require('jquery');

/*
//cross domain : not allowed
jQuery(function(){
  var frame = document.getElementById('medtack_iframe');
  frame.onload = function() {
    frame.style.height = frame.contentWindow.document.body.scrollHeight + 'px';
    alert(frame.style.height);
  }
});
*/

//remove style
/*
jQuery(function() {
  jQuery("#medtack_iframe").parent().removeClass("fluid-width-video-wrapper");
  jQuery("#medtack_iframe").parent().css("padding-top","0");
});

window.onload = function() {
  jQuery("#medtack_iframe").parent().removeClass("fluid-width-video-wrapper");
  jQuery("#medtack_iframe").parent().css("padding-top","0");
}
*/

//ADDED "fitvidsignore" to > advanced > CSS Class divi block












//after parent has loaded, request iFrame to send it's size
jQuery(function () {
  console.log("TO IFRAME: getIframeSize");
  var iFrame = document.getElementById('medtack_iframe');
  if (iFrame) {
    iFrame.contentWindow.postMessage({ "getIframeSize": "yes" }, "*");
  }
});



window.addEventListener('message', event => {



  /*
  if (event.origin.startsWith('http://www.medtack.net')) {
    console.log(event.origin);
  } else {
    return;
  }
  */



  //AUTO REDIRECT TO HOME PAGE
  if (event.data.getCurrentURL == "yes") {
    console.log("FROM IFRAME: event.data.getCurrentURL: " + event.data.getCurrentURL);
    console.log("FROM IFRAME: event.data.v2_registration_page_url: " + event.data.v2_registration_page_url);
    console.log("FROM IFRAME: event.data.v2_questionnaire_page_url: " + event.data.v2_questionnaire_page_url);
    console.log("FROM IFRAME: event.data.v2_webinar_page_url: " + event.data.v2_webinar_page_url);
    console.log("FROM IFRAME: event.data.new_trial_applicant_id: " + event.data.new_trial_applicant_id);

    //redirect to home if on registration or webinar page and new_trial_applicant_id is empty
    if (window.location.href.indexOf(event.data.v2_registration_page_url) > -1 || window.location.href.indexOf(event.data.v2_webinar_menu_page_url) > -1) {
      if (event.data.new_trial_applicant_id == "" || event.data.new_trial_applicant_id == null) {
        top.location.href = event.data.v2_questionnaire_page_url;
      }
    }

  }


  //GET PARENT WIDTH
  if (event.data.get_parent_width != "" && event.data.get_parent_width != "undefined" && typeof event.data.get_parent_width != "undefined") {
    console.log("FROM IFRAME: event.data.get_parent_width: " + event.data.get_parent_width);

    // Size of browser viewport.
    var height = jQuery(window).height();
    var width = jQuery(window).width();

    // Size of HTML document (same as pageHeight/pageWidth in screenshot).
    //$(document).height();
    //$(document).width();

    var iFrame = document.getElementById('medtack_iframe');
    iFrame.contentWindow.postMessage({ "get_parent_width": width }, "*");

  }


  //GET IFRAME HEIGHT
  if (event.data.height != "" && event.data.height != "undefined" && typeof event.data.height != "undefined") {
    console.log("FROM IFRAME: event.data.height: " + event.data.height);
    var iFrame = document.getElementById('medtack_iframe');
    iFrame.style.height = event.data.height + 'px';

    //parent
    //jQuery("#medtack_iframe").parent().css("max-height",event.data.height + 'px');


  }

  //PAGE REDIRECT
  //registration / pass / fail / webinar URLs from iframe
  if (event.data.url != "" && event.data.url != "undefined" && typeof event.data.url != "undefined") {

    console.log("FROM IFRAME: event.data.url: " + event.data.url);

    console.log("url: " + window.location.href + " event.data.url: " + event.data.url);
    if (event.data.url != "" && window.location.href.indexOf(event.data.url) > -1) {
      //no redirection
      //only redirect if not on the page
    } else {
      top.location.href = event.data.url;
    }
  }


  //CHECK CURRENT PAGE

  //EVENT LISTENER IN IFRAME
  //window.addEventListener('message', event => {

  //1] iframe checks if current url is registration by sending url_registration
  //2] send redirect info to iframe if on registration page
  if (event.data.url_registration != "" && event.data.url_registration != "undefined" && typeof event.data.url_registration != "undefined") {

    //auto redirect to second page
    if (event.data.url_registration != "" && window.location.href.indexOf(event.data.url_registration) > -1) {
      console.log("TO IFRAME: event.data.url_registration: " + event.data.url_registration);
      var iFrame = document.getElementById('medtack_iframe');
      iFrame.contentWindow.postMessage({ "redirect": event.data.url_registration }, "*");
    }
  }


  //1] iframe checks if current url is webinar by sending url_webinar
  //2] send redirect info to iframe if on webinar page
  if (event.data.url_webinar != "" && event.data.url_webinar != "undefined" && typeof event.data.url_webinar != "undefined") {

    //auto redirect to second page
    if (event.data.url_webinar != "" && window.location.href.indexOf(event.data.url_webinar) > -1) {
      console.log("TO IFRAME: event.data.url_webinar: " + event.data.url_webinar);
      var iFrame = document.getElementById('medtack_iframe');
      iFrame.contentWindow.postMessage({ "redirect": event.data.url_webinar }, "*");
    }
  }



  //1] iframe requests adid
  if (event.data.get_adid != "" && event.data.get_adid != "undefined" && typeof event.data.get_adid != "undefined") {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var adid = params.adid;

    console.log("TO IFRAME: ADID: " + adid);

    if (typeof adid != "undefined") {
      var iFrame = document.getElementById('medtack_iframe');
      iFrame.contentWindow.postMessage({ "adid": adid }, "*");
    }
  }


  //1] iframe requests sid
  if (event.data.get_sid != "" && event.data.get_sid != "undefined" && typeof event.data.get_sid != "undefined") {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var sid = params.sid;

    console.log("TO IFRAME: SID: " + sid);

    if (typeof sid != "undefined") {
      var iFrame = document.getElementById('medtack_iframe');
      iFrame.contentWindow.postMessage({ "sid": sid }, "*");
    }
  }


  //1] iframe requests scroll to top
  if (event.data.scroll_to_top != "" && event.data.scroll_to_top != "undefined" && typeof event.data.scroll_to_top != "undefined") {

    console.log("FROM IFRAME: scroll_to_top");
    window.scrollTo(0, 0);
  }






}, false);

//end window.addEventListener('message', event => {






